import { reactive, ref } from 'vue'
import axios from '../plugins/axios'
import { user } from '@/core/auth'
import { showNotif } from '@/core/utility'

export const listHasil = ref([])
export const detailHasil = ref([])
export const listLampiran = ref([])
export const listLampiranMcu = ref([])
export const pdfMcu = ref(null)
export const downloadLampiran = ref('')
export const monitoring = reactive({
  header: {},
  detail: []
})

export const androidMonitoring = reactive({
  header: {},
  detail: []
})

export const loading = reactive({
  hasil: false,
  lampiran: false,
  lampiranMcu: false,
  pdfMcu: false,
  monitoring: false,
  androidMonitoring: false,
  confirmHasil: false
})

export const getHasil = async () => {
  try {
    loading.hasil = true
    const { data } = await axios.post('hasil/data_periksa', {
      id: user.value.id
    })

    if (data.success) {
      listHasil.value = data.data
    }
  } catch (error) {
    console.error('error get list hasil = ', error)
    throw error
  } finally {
    loading.hasil = false
  }
}

export const getDetail = async (no_lab, cabang) => {
  try {
    loading.hasil = true
    // reset variable dulu
    detailHasil.value = []
    const { data } = await axios.post('hasil/data_hasil', {
      id: user.value.id,
      no_lab: no_lab,
      cabang: cabang
    })

    if (data.success) {
      detailHasil.value = data
    }
  } catch (error) {
    console.error('error get detail hasil = ', error)
    throw error
  } finally {
    loading.hasil = false
  }
}

export const getLampiran = async (no_lab, cabang) => {
  try {
    loading.lampiran = true

    // reset dulu lampirannya
    listLampiran.value = []

    const { data } = await axios.post('hasil/data_pemeriksaan_lain', {
      id: user.value.id,
      no_lab: no_lab,
      cabang: cabang
    })

    if (data.success) {
      listLampiran.value = data.data
    }
  } catch (error) {
    console.error('error get detail hasil = ', error)
    throw error
  } finally {
    loading.lampiran = false
  }
}

export const getLampiranMcu = async (no_lab, cabang) => {
  try {
    loading.lampiranMcu = true

    // reset dulu lampirannya
    listLampiranMcu.value = []

    const { data } = await axios.post('hasil/data_periksa_mcu', {
      id: user.value.id,
      no_lab: no_lab,
      cabang: cabang
    })

    if (data.success) {
      listLampiranMcu.value = data.data
    }
  } catch (error) {
    console.error('error get detail hasil = ', error)
    throw error
  } finally {
    loading.lampiranMcu = false
  }
}

export const getPdfMcu = async (no_lab, cabang) => {
  try {
    loading.pdfMcu = true

    // reset dulu lampirannya
    pdfMcu.value = null

    const { data } = await axios.post('hasil/data_periksa_mcu_pdf', {
      id: user.value.id,
      no_lab: no_lab,
      cabang: cabang
    })

    if (data.success) {
      pdfMcu.value = data.data
    }
  } catch (error) {
    console.error('error get lampiran pdf mcu = ', error)
    throw error
  } finally {
    loading.pdfMcu = false
  }
}

export const getMonitoring = async (payload) => {
  try {
    loading.monitoring = true
    const { data } = await axios.post('hasil/monitoring', payload)

    if (data.success) {
      monitoring.header = data.header
      monitoring.detail = data.detail
      return {
        status: true,
        message: 'Berhasil ambil data monitoring'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error get monitoring = ', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.monitoring = false
  }
}

export const getDownloadLampiran = async (no_lab, cabang) => {
  try {
    const { data } = await axios.post('hasil/hasil_download_nolab', {
      no_lab: no_lab,
      cabang: cabang,
      id: user.value.id
    })

    if (data.success) {
      downloadLampiran.value = data.data
      return {
        status: true,
        message: 'Berhasil ambil data download lampiran'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error downloadLampiran = ', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  }
}

export const getAndroidMonitoring = async (payload) => {
  try {
    loading.androidMonitoring = true
    const { data } = await axios.get('dokter/monitoring', {
      params: payload
    })

    if (data.success) {
      androidMonitoring.header = data.header
      androidMonitoring.detail = data.detail
      return {
        status: true,
        message: 'Berhasil ambil data monitoring'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error get android monitoring = ', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.androidMonitoring = false
  }
}

export const confirmHasil = async (cabang, no_lab) => {
  try {
    loading.confirmHasil = true
    const { data } = await axios.post('hasil/konfirmasi_hasil', {
      id: user.value.id,
      token_api: user.value.token_api,
      no_lab: no_lab,
      cabang: cabang,
      source: 'WEB'
    })

    if (data.success === true) {
      return {
        status: true,
        message: data.message
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari hasil konfirmasi hasil', error)
    throw error
  } finally {
    loading.confirmHasil = false
  }
}
